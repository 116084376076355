export const connectedNetwork = "mainnet";
export const rpcNode = "https://rpc.tzkt.io/mainnet";


export const teztrisContract = 'KT1TkkM9g5TB2sZ86aomf1tF2kEVC5Yec6jU';
export const nftContract = 'KT1Vz5Epeh12sh3oXZRXCqYe2tTV5phA82AH';

// ghostnet
//  KT1Q4qRd8mKS7eWUgTfJzCN8RC6h9CzzjVJb
export const ctez = {
    betToken : "KT1SjXiUX63QvdNMcM2m492f7kuf8JxXRLp4",
    betTokenId: 0,
    betTokenType : "FA1.2",
    betTokenDecimal : 6,
}

// ghostnet
//  KT1Uw1oio434UoWFuZTNKFgt5wTM9tfuf7m7
export const usdt = {
    betToken : "KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o",
    betTokenId: 3,
    betTokenType : "FA2",
    betTokenDecimal : 6,
}

export const tez = {
    betToken : "KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o",
    betTokenId: 0,
    betTokenType : "tez",
    betTokenDecimal : 6,
}